import React, { useState } from "react";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useDispatch, useSelector } from "react-redux";
import SendIcon from "@material-ui/icons/Telegram";
import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import AddPart from "./managePart/AddPart";
import RemovePart from "./managePart/RemovePart";
import { actions } from "../../../../actions/warehouseActions";
import MovePart from "./managePart/MovePart";

const PartListActionMenu = ({ part }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const [showAddPartDialog, setShowAddPartDialog] = useState(false);
  const [showRemovePartDialog, setRemoveAddPartDialog] = useState(false);
  const [showMovePartDialog, setMoveAddPartDialog] = useState(false);

  const warehouseState = useSelector((state) => state.warehouse);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const onHandleAddPartSubmit = async (data) => {
    const result = await dispatch(
      actions.addBikePart(part.id, data.newPartQuantity, data.usedPartQuantity),
    );
    if (result.success) {
      setShowAddPartDialog(false);
      const pagedData = warehouseState.bikePartList;
      dispatch(
        actions.loadBikeParts(
          1,
          pagedData.paging.pageSize,
          pagedData.sortedBy,
          pagedData.filteredBy,
        ),
      );
    }
  };

  const onHandleMovePartSubmit = async (data) => {
    const result = await dispatch(
      actions.moveBikePart(
        part.id,
        data.newPartQuantity,
        data.usedPartQuantity,
        data.targetWarehouseId,
      ),
    );
    if (result.success) {
      setShowAddPartDialog(false);
      const pagedData = warehouseState.bikePartList;
      dispatch(
        actions.loadBikeParts(
          1,
          pagedData.paging.pageSize,
          pagedData.sortedBy,
          pagedData.filteredBy,
        ),
      );
    }
  };
  const onHandleRemovePartSubmit = async (data) => {
    const result = await dispatch(
      actions.removeBikePart(
        part.id,
        data.newPartQuantity,
        data.usedPartQuantity,
      ),
    );
    if (result.success) {
      setRemoveAddPartDialog(false);
      const pagedData = warehouseState.bikePartList;
      dispatch(
        actions.loadBikeParts(
          1,
          pagedData.paging.pageSize,
          pagedData.sortedBy,
          pagedData.filteredBy,
        ),
      );
    }
  };

  return (
    <>
      <div>
        <IconButton
          aria-controls="actions-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              setMoveAddPartDialog(true);
            }}
            aria-label="more"
            aria-controls="commands-menu"
            aria-haspopup="true"
          >
            <ListItemIcon>
              <SendIcon />
            </ListItemIcon>
            <Typography variant="inherit">Wyślij do innego magazynu</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setShowAddPartDialog(true);
            }}
            aria-label="more"
            aria-controls="commands-menu"
            aria-haspopup="true"
          >
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <Typography variant="inherit">Dodaj</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setRemoveAddPartDialog(true);
            }}
            aria-label="more"
            aria-controls="commands-menu"
            aria-haspopup="true"
          >
            <ListItemIcon>
              <RemoveIcon />
            </ListItemIcon>
            <Typography variant="inherit">Usuń</Typography>
          </MenuItem>
        </Menu>
        <AddPart
          open={showAddPartDialog}
          onClose={() => {
            setShowAddPartDialog(false);
          }}
          onSubmit={onHandleAddPartSubmit}
        />
        <RemovePart
          open={showRemovePartDialog}
          onClose={() => {
            setRemoveAddPartDialog(false);
          }}
          onSubmit={onHandleRemovePartSubmit}
        />
        <MovePart
          open={showMovePartDialog}
          onClose={() => {
            setMoveAddPartDialog(false);
          }}
          onSubmit={onHandleMovePartSubmit}
        />
      </div>
    </>
  );
};

PartListActionMenu.propTypes = {
  part: PropTypes.object.isRequired,
};

export default PartListActionMenu;
